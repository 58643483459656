(function () {
  /* global localstorage */
  'use strict';

  angular
    .module('neo')
    .config(config)
    .run(run);

  function config($urlRouterProvider, $httpProvider, jwtOptionsProvider, EnvironmentConfig) {
    $urlRouterProvider.otherwise('/home');

    $httpProvider.interceptors.push('jwtInterceptor');
    $httpProvider.interceptors.push('HttpInterceptorNoServerFound');
    $httpProvider.interceptors.push('HttpInterceptorSpinner');

    // Security
    jwtOptionsProvider.config({
      tokenGetter: ['options', function (options) {
        // Skip authentication for any requests ending in .html
        if (options && options.url.substr(options.url.length - 5) === '.html') {
          return null;
        }
        return localStorage.getItem('id_token');
      }],
      whiteListedDomains: EnvironmentConfig.whiteListedDomains,
      unauthenticatedRedirector: ['$state', function ($state) {
        $state.go('authentication.login');
      }]
    });
  }


  function run(authManager, $rootScope, AuthenticationService, $log, $mdToast, $filter) {
    authManager.checkAuthOnRefresh();
    authManager.redirectWhenUnauthenticated();

    //inicialization of http timeouts for the spinner;
    $rootScope.timeoutPromises = [];

    $rootScope.$on('$stateChangeStart', function (event, toState) {
      $rootScope.changingState = true;
    });

    $rootScope.$on('tokenHasExpired', function () {
      $log.log('Your session has expired!');
      AuthenticationService.logout();
    });

    $rootScope.$on('$stateChangeSuccess',
      function(event, toState, toParams, fromState, fromParams){
        $rootScope.changingState = false;
      });

    $rootScope.$on('$stateNotFound', function(){
      $rootScope.changingState = false;
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.changingState = false;
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('STATE_CHANGE_ERROR'))
          .position('bottom left')
          .hideDelay(3000)
      );
    });
  }
}());
